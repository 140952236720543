define('imgix-inspector/routes/create', ['exports', 'ember', 'jquery', 'imgix-inspector/routes/base'], function (exports, _ember, _jquery, _imgixInspectorRoutesBase) {
  exports['default'] = _imgixInspectorRoutesBase['default'].extend({
    detect: _ember['default'].inject.service(),

    activate: function activate() {
      var _this = this;

      this._super();
      _ember['default'].run.later(function () {
        if (!_this.get('detect.isMobile') && !_this.get('detect.isTablet')) {
          // Prevent the keyboard from opening on mobile and crowding the initial experience
          (0, _jquery['default'])('input:first').focus();
        }
      });
    }
  });
});