define('imgix-inspector/router', ['exports', 'ember', 'imgix-inspector/config/environment'], function (exports, _ember, _imgixInspectorConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _imgixInspectorConfigEnvironment['default'].locationType
  });

  // Make sure to notify Segment when we change pages
  Router.reopen({
    didTransition: function didTransition(transitions) {
      analytics.page();
      this._super.apply(this, transitions);
    }
  });

  Router.map(function () {
    this.route('create');
    this.route('view');
    this.route('sales-demo');
    this.route('about');
  });

  exports['default'] = Router;
});
/* global analytics */