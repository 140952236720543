define('imgix-inspector/components/small-file-uploader', ['exports', 'ember', 'imgix-inspector/mixins/uploads-files'], function (exports, _ember, _imgixInspectorMixinsUploadsFiles) {
  exports['default'] = _ember['default'].Component.extend(_imgixInspectorMixinsUploadsFiles['default'], {
    classNames: "small-file-uploader",

    actions: {
      handleFileDragEnter: function handleFileDragEnter() {
        this.$('.drop-zone').addClass('dragging');
      },
      handleFileDragLeave: function handleFileDragLeave() {
        this.$('.drop-zone').removeClass('dragging');
      },
      setFile: function setFile(e) {
        if (this.attrs.uploadStarted) {
          this.attrs.uploadStarted();
        }

        var dropZone = this.$('.drop-zone');
        var file = e.target.files[0];

        dropZone.removeClass('dragging');

        if (!this.validateFile(file)) {
          return;
        }

        dropZone.addClass('has-image');
        this.uploadFile(file);
      }
    }
  });
});