define("imgix-inspector/templates/components/imgix-input-row", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 0
            },
            "end": {
              "line": 23,
              "column": 0
            }
          },
          "moduleName": "imgix-inspector/templates/components/imgix-input-row.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "href", "#");
          dom.setAttribute(el1, "class", "button build");
          dom.setAttribute(el1, "tabindex", "-1");
          var el2 = dom.createTextNode("+");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["element", "action", ["buildUrl"], [], ["loc", [null, [22, 14], [22, 35]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 0
          }
        },
        "moduleName": "imgix-inspector/templates/components/imgix-input-row.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "set");
        var el2 = dom.createTextNode("=");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("a");
        dom.setAttribute(el1, "href", "#");
        dom.setAttribute(el1, "class", "button remove");
        dom.setAttribute(el1, "tabindex", "-1");
        var el2 = dom.createTextNode("×");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [6]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[2] = dom.createElementMorph(element1);
        morphs[3] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "smart-input-with-parameter-validation", [], ["value", ["subexpr", "@mut", [["get", "param.key", ["loc", [null, [2, 8], [2, 17]]]]], [], []], "availableParameters", ["subexpr", "@mut", [["get", "availableParameters", ["loc", [null, [3, 22], [3, 41]]]]], [], []], "deprecatedValues", ["subexpr", "@mut", [["get", "deprecatedParameterKeys", ["loc", [null, [4, 19], [4, 42]]]]], [], []], "useSelect2", ["subexpr", "@mut", [["get", "useSelect2", ["loc", [null, [5, 13], [5, 23]]]]], [], []], "onEnter", ["subexpr", "@mut", [["get", "onEnter", ["loc", [null, [6, 10], [6, 17]]]]], [], []], "onChange", ["subexpr", "action", ["onKeyChange"], [], ["loc", [null, [7, 11], [7, 33]]]]], ["loc", [null, [1, 0], [8, 2]]]], ["inline", "smart-input-with-parameter-typing", [], ["value", ["subexpr", "@mut", [["get", "paramValue", ["loc", [null, [12, 8], [12, 18]]]]], [], []], "selectedParameter", ["subexpr", "@mut", [["get", "param.key", ["loc", [null, [13, 20], [13, 29]]]]], [], []], "availableParameters", ["subexpr", "@mut", [["get", "availableParameters", ["loc", [null, [14, 22], [14, 41]]]]], [], []], "onEnter", ["subexpr", "@mut", [["get", "onEnter", ["loc", [null, [15, 10], [15, 17]]]]], [], []], "onChange", ["subexpr", "action", ["onValueChange"], [], ["loc", [null, [16, 11], [16, 35]]]], "class", "param-value"], ["loc", [null, [11, 0], [18, 2]]]], ["element", "action", ["removeItem"], [], ["loc", [null, [19, 12], [19, 35]]]], ["block", "if", [["get", "param.takesUrlAsValue", ["loc", [null, [21, 6], [21, 27]]]]], [], 0, null, ["loc", [null, [21, 0], [23, 7]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});