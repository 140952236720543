define('imgix-inspector/components/image-uploader', ['exports', 'ember', 'imgix-inspector/mixins/uploads-files'], function (exports, _ember, _imgixInspectorMixinsUploadsFiles) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend(_imgixInspectorMixinsUploadsFiles['default'], {
    file: null,
    hasAgreedToUploadTerms: false,

    disableUploadButton: computed('uploading', 'hasAgreedToUploadTerms', function () {
      return this.get('uploading') || !this.get('hasAgreedToUploadTerms');
    }),

    fileName: computed('file', function () {
      if (this.get('file')) {
        var val = this.$('input[type="file"]').val();
        return val.substr(val.lastIndexOf('\\') + 1);
      } else {
        return null;
      }
    }),

    _config: computed(function () {
      return this.container.lookupFactory('config:environment');
    }),

    createAndNavigateToImageUrl: function createAndNavigateToImageUrl(imageUrl) {
      this.attrs.onUploadSuccess(imageUrl, true);
    },

    actions: {
      go: function go() {
        this.uploadFile(this.get('file'));
      },
      handleFileDragEnter: function handleFileDragEnter() {
        this.$('.drop-zone').addClass('dragging');
      },
      handleFileDragLeave: function handleFileDragLeave() {
        this.$('.drop-zone').removeClass('dragging');
      },
      setFile: function setFile(e) {
        var dropZone = this.$('.drop-zone');
        var file = e.target.files[0];

        dropZone.removeClass('dragging');

        if (!this.validateFile(file)) {
          return;
        }

        dropZone.addClass('has-image');

        var reader = new FileReader();

        reader.onload = function (e) {
          var dataUrl = e.target.result;
          dropZone.css('background-image', 'url(' + dataUrl + ')');
        };

        reader.readAsDataURL(file);
        this.set('file', file);
      },
      examples: function examples() {
        _ember['default'].$('body').scrollTop(_ember['default'].$('[name="try-out-examples"]').offset().top);
      }
    }
  });
});