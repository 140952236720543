define('imgix-inspector/app', ['exports', 'ember', 'imgix-inspector/resolver', 'ember-load-initializers', 'imgix-inspector/config/environment'], function (exports, _ember, _imgixInspectorResolver, _emberLoadInitializers, _imgixInspectorConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _imgixInspectorConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _imgixInspectorConfigEnvironment['default'].podModulePrefix,
    Resolver: _imgixInspectorResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _imgixInspectorConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});