define('imgix-inspector/routes/view', ['exports', 'ember', 'imgix-inspector/models/param', 'imgix-inspector/routes/base', 'uri.js'], function (exports, _ember, _imgixInspectorModelsParam, _imgixInspectorRoutesBase, _uriJs) {
  exports['default'] = _imgixInspectorRoutesBase['default'].extend({
    queryParams: {
      url: {
        replace: true
      }
    },

    'default': {
      protocol: "https",
      source: "assets.imgix.net",
      path: "/flower.jpg",
      parameters: [_imgixInspectorModelsParam['default'].create({ key: "w", value: "400" }), _imgixInspectorModelsParam['default'].create({ key: "h", value: "300" }), _imgixInspectorModelsParam['default'].create({ key: "fit", value: "crop" })]
    },

    paramsToFilter: ["ixjsv", "ixlib"],

    /**
     * Breaks the `url` property into `protocol`, `source`, `path`, and
     * `params` properties. This has to be a method instead of a set of properties
     * due to the timing at which url parameter properties get applied to their controllers
     */
    decomposeUrl: function decomposeUrl(url) {
      if (!url) {
        return this.get('default');
      }

      var uri = (0, _uriJs['default'])(url);
      var params = _ember['default'].A([]);
      var urlParams = uri.search(true);
      for (var key in urlParams) {
        if (urlParams.hasOwnProperty(key) && !this.get('paramsToFilter').contains(key)) {
          var val = undefined;

          if (key.substr(-2) === '64') {
            val = Base64.decode(urlParams[key]);
            key = key.slice(0, -2);
          } else {
            val = urlParams[key];
          }

          params.addObject(_imgixInspectorModelsParam['default'].create({ key: key, value: val }));
        }
      }

      return {
        protocol: "https",
        source: uri.hostname(),
        path: uri.path(),
        parameters: params
      };
    },

    model: function model(params) {
      var _decomposeUrl = this.decomposeUrl(params.url);

      var protocol = _decomposeUrl.protocol;
      var source = _decomposeUrl.source;
      var path = _decomposeUrl.path;
      var parameters = _decomposeUrl.parameters;

      return _ember['default'].Object.create({
        protocol: protocol,
        source: source,
        path: path,
        params: parameters
      });
    }
  });
});
/* global Base64 */