define('imgix-inspector/controllers/sales-demo', ['exports', 'ember', 'uri.js'], function (exports, _ember, _uriJs) {
  exports['default'] = _ember['default'].Controller.extend({
    source: "sales-demo.imgix.net",

    /**
     * `path` in this case should be a full-qualified URL from a lead's site
     * @property {string}
     */
    path: null,

    /**
     * The `secureUrlToken` should be provided by the sales rep for the sales-demo.imgix.net
     * source.
     *
     * @property {string}
     */
    secureUrlToken: null,

    actions: {
      go: function go() {
        var url = (0, _uriJs['default'])('https://' + this.get('source'));
        url.pathname(this.get('path'));

        this.transitionToRoute('view', {
          queryParams: {
            url: url.toString(),
            secureUrlToken: this.get('secureUrlToken')
          }
        });

        this.setProperties({
          path: null,
          secureUrlToken: null
        });
      }
    }
  });
});