define('imgix-inspector/helpers/number-to-human-size', ['exports', 'ember-number-to-human-size/helpers/number-to-human-size'], function (exports, _emberNumberToHumanSizeHelpersNumberToHumanSize) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberNumberToHumanSizeHelpersNumberToHumanSize['default'];
    }
  });
  Object.defineProperty(exports, 'numberToHumanSize', {
    enumerable: true,
    get: function get() {
      return _emberNumberToHumanSizeHelpersNumberToHumanSize.numberToHumanSize;
    }
  });
});