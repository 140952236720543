define('imgix-inspector/services/detect', ['exports', 'ember', 'bowser'], function (exports, _ember, _bowser) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Service.extend({
    isMobile: computed(function () {
      return _bowser['default'].mobile;
    }),

    isTablet: computed(function () {
      return _bowser['default'].tablet;
    }),

    isDesktop: computed('isMobile', 'isTablet', function () {
      return !this.get('isMobile') && !this.get('isTablet');
    })
  });
});