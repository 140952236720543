define('imgix-inspector/components/imgix-image-container', ['exports', 'ember', 'jquery', 'imgix-inspector/models/promise-object'], function (exports, _ember, _jquery, _imgixInspectorModelsPromiseObject) {
  var computed = _ember['default'].computed;
  var on = _ember['default'].on;

  var propComputed = function propComputed(key, prop) {
    return computed('resizeCounter', key, function () {
      if (!this.get(key)) {
        return null;
      }

      return this.get(key).prop(prop);
    });
  };

  exports['default'] = _ember['default'].Component.extend({
    src: null,
    sizes: _ember['default'].inject.service(),
    resizeCounter: 0,

    sizesPromise: computed('src', function () {
      if (!this.get('src')) {
        return null;
      }

      return _imgixInspectorModelsPromiseObject['default'].create({
        promise: this.get('sizes').sizePromiseFor(this.get('src'))
      });
    }),

    shouldDisplayResizeBanner: computed('displayWidth', 'modifiedWidth', function () {
      return !!this.get('displayWidth') && !!this.get('modifiedWidth') && this.get('displayWidth') !== this.get('modifiedWidth');
    }),

    _listenToWindowEvents: on('init', function () {
      var _this = this;

      (0, _jquery['default'])(window).resize(function () {
        _this.incrementProperty('resizeCounter');
      });
    }),

    modifiedHeight: computed.alias('sizesPromise.content.height'),
    modifiedWidth: computed.alias('sizesPromise.content.width'),

    displayHeight: propComputed('_imageElement', 'clientHeight'),
    displayWidth: propComputed('_imageElement', 'clientWidth'),

    actions: {
      imageLoaded: function imageLoaded(imageElement) {
        this.set('_imageElement', imageElement);
      },

      imageErrored: function imageErrored() {
        this.setProperties({
          '_imageElement': null
        });
      }
    }
  });
});