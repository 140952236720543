define("imgix-inspector/templates/view", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 8
              },
              "end": {
                "line": 11,
                "column": 8
              }
            },
            "moduleName": "imgix-inspector/templates/view.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("img");
            dom.setAttribute(el1, "srcset", "https://assets.imgix.net/presskit/imgix-presskit.pdf?page=4&fm=png&w=120&dpr=2 2x, https://assets.imgix.net/presskit/imgix-presskit.pdf?page=4&fm=png&w=120 1x");
            dom.setAttribute(el1, "src", "https://assets.imgix.net/presskit/imgix-presskit.pdf?page=4&fm=png&w=120&dpr=2");
            dom.setAttribute(el1, "width", "120");
            dom.setAttribute(el1, "height", "60");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "logo-text");
            var el2 = dom.createTextNode("Sandbox");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "triple-curlies"
          },
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 35,
              "column": 0
            }
          },
          "moduleName": "imgix-inspector/templates/view.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("section");
          dom.setAttribute(el1, "class", "imgix-info");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "imgix-row");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "info-block");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "info-block info-block_links");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4, "href", "https://dashboard.imgix.com/sign-up");
          dom.setAttribute(el4, "class", "button action");
          dom.setAttribute(el4, "tabindex", "-1");
          dom.setAttribute(el4, "target", "_blank");
          var el5 = dom.createTextNode("Sign Up");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4, "href", "https://www.imgix.com/contact-us");
          dom.setAttribute(el4, "class", "button action");
          dom.setAttribute(el4, "tabindex", "-1");
          dom.setAttribute(el4, "target", "_blank");
          var el5 = dom.createTextNode("Contact Us");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("a");
          dom.setAttribute(el4, "href", "https://docs.imgix.com/apis/url");
          dom.setAttribute(el4, "class", "button action");
          dom.setAttribute(el4, "tabindex", "-1");
          dom.setAttribute(el4, "target", "_blank");
          var el5 = dom.createTextNode("imgix API Reference");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element10 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element10, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(element10, 5, 5);
          morphs[2] = dom.createMorphAt(element10, 7, 7);
          return morphs;
        },
        statements: [["block", "link-to", ["create"], ["class", "logo", "tabindex", "-1"], 0, null, ["loc", [null, [5, 8], [11, 20]]]], ["inline", "info-block", [], ["class", "info-original", "name", "Original", "src", ["subexpr", "@mut", [["get", "originalUrl", ["loc", [null, [23, 12], [23, 23]]]]], [], []]], ["loc", [null, [20, 6], [24, 8]]]], ["inline", "info-block", [], ["class", "info-new", "name", "Modified", "src", ["subexpr", "@mut", [["get", "newUrl", ["loc", [null, [29, 12], [29, 18]]]]], [], []], "contentTypeChanged", ["subexpr", "action", ["contentTypeChanged"], [], ["loc", [null, [30, 27], [30, 56]]]]], ["loc", [null, [26, 6], [31, 8]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 44,
              "column": 0
            },
            "end": {
              "line": 48,
              "column": 0
            }
          },
          "moduleName": "imgix-inspector/templates/view.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "progress");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "bar");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element9, 'style');
          return morphs;
        },
        statements: [["attribute", "style", ["concat", ["width: ", ["get", "uploadProgress", ["loc", [null, [46, 37], [46, 51]]]], "%;"]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 57,
                "column": 10
              },
              "end": {
                "line": 59,
                "column": 10
              }
            },
            "moduleName": "imgix-inspector/templates/view.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("option");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element6, 'value');
            morphs[1] = dom.createAttrMorph(element6, 'selected');
            morphs[2] = dom.createMorphAt(element6, 0, 0);
            return morphs;
          },
          statements: [["attribute", "value", ["concat", [["get", "p", ["loc", [null, [58, 29], [58, 30]]]]]]], ["attribute", "selected", ["subexpr", "is-equal", [["get", "path", ["loc", [null, [58, 54], [58, 58]]]], ["get", "p", ["loc", [null, [58, 59], [58, 60]]]]], [], ["loc", [null, [58, 43], [58, 62]]]]], ["content", "p", ["loc", [null, [58, 63], [58, 68]]]]],
          locals: ["p"],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 2
            },
            "end": {
              "line": 64,
              "column": 2
            }
          },
          "moduleName": "imgix-inspector/templates/view.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "imgix-row imgix-url-row");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "select-wrapper");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("select");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var element8 = dom.childAt(element7, [3, 1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element7, 1, 1);
          morphs[1] = dom.createAttrMorph(element8, 'onchange');
          morphs[2] = dom.createMorphAt(element8, 1, 1);
          return morphs;
        },
        statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "source", ["loc", [null, [53, 20], [53, 26]]]]], [], []], "disabled", true, "class", "source-name-input"], ["loc", [null, [53, 6], [53, 68]]]], ["attribute", "onchange", ["subexpr", "action", ["selectChanged"], [], ["loc", [null, [56, 25], [56, 51]]]]], ["block", "each", [["get", "paths", ["loc", [null, [57, 18], [57, 23]]]]], [], 0, null, ["loc", [null, [57, 10], [59, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 69,
              "column": 2
            },
            "end": {
              "line": 79,
              "column": 2
            }
          },
          "moduleName": "imgix-inspector/templates/view.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "imgix-input-row", [], ["param", ["subexpr", "@mut", [["get", "param", ["loc", [null, [71, 12], [71, 17]]]]], [], []], "availableParameters", ["subexpr", "@mut", [["get", "availableParameters", ["loc", [null, [72, 26], [72, 45]]]]], [], []], "deprecatedValues", ["subexpr", "@mut", [["get", "deprecatedParameterKeys", ["loc", [null, [73, 23], [73, 46]]]]], [], []], "useSelect2", ["subexpr", "@mut", [["get", "useSelect2", ["loc", [null, [74, 17], [74, 27]]]]], [], []], "onEnter", ["subexpr", "action", ["addNew"], [], ["loc", [null, [75, 14], [75, 31]]]], "removeItem", ["subexpr", "action", ["removeItem"], [], ["loc", [null, [76, 17], [76, 38]]]], "buildUrl", ["subexpr", "action", ["buildUrl"], [], ["loc", [null, [77, 15], [77, 34]]]]], ["loc", [null, [70, 4], [78, 6]]]]],
        locals: ["param"],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 102,
                "column": 6
              },
              "end": {
                "line": 108,
                "column": 6
              }
            },
            "moduleName": "imgix-inspector/templates/view.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "wrapper");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "href", "#");
            dom.setAttribute(el2, "class", "example");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(element0, 1, 1);
            return morphs;
          },
          statements: [["element", "action", ["replaceImage", ["get", "image.viewUrl", ["loc", [null, [104, 60], [104, 73]]]], 1], [], ["loc", [null, [104, 36], [104, 77]]]], ["inline", "imgix-image-element", [], ["path", ["subexpr", "@mut", [["get", "image.url", ["loc", [null, [105, 37], [105, 46]]]]], [], []], "crossorigin", "anonymous", "aspectRatio", 1.3333], ["loc", [null, [105, 10], [105, 91]]]]],
          locals: ["image"],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 110,
                "column": 6
              },
              "end": {
                "line": 116,
                "column": 6
              }
            },
            "moduleName": "imgix-inspector/templates/view.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "small-file-uploader", [], ["uploadStarted", ["subexpr", "action", ["onUploadStart"], [], ["loc", [null, [112, 24], [112, 48]]]], "uploadProgressed", ["subexpr", "action", ["onUploadProgress"], [], ["loc", [null, [113, 27], [113, 54]]]], "onUploadSuccess", ["subexpr", "action", ["replaceImage"], [], ["loc", [null, [114, 26], [114, 49]]]]], ["loc", [null, [111, 8], [115, 10]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 81,
              "column": 0
            },
            "end": {
              "line": 120,
              "column": 0
            }
          },
          "moduleName": "imgix-inspector/templates/view.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("section");
          dom.setAttribute(el1, "class", "imgix-share");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "imgix-row buttons");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "class", "button request");
          dom.setAttribute(el3, "target", "_blank");
          var el4 = dom.createTextNode("Download Image");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "target", "_blank");
          dom.setAttribute(el3, "class", "button request");
          var el4 = dom.createTextNode("Open in New Sandbox");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("section");
          dom.setAttribute(el1, "class", "imgix-examples view-examples");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "imgix-row");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("hr");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "imgix-row");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h1");
          var el4 = dom.createTextNode("See how these parameters affect other images");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "imgix-row");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [3]);
          var element3 = dom.childAt(element2, [1]);
          var element4 = dom.childAt(element2, [3]);
          var element5 = dom.childAt(fragment, [3, 5]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(element1, 1, 1);
          morphs[1] = dom.createAttrMorph(element3, 'href');
          morphs[2] = dom.createAttrMorph(element4, 'href');
          morphs[3] = dom.createMorphAt(element5, 1, 1);
          morphs[4] = dom.createMorphAt(element5, 3, 3);
          morphs[5] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          return morphs;
        },
        statements: [["inline", "share-section", [], ["name", "Modified Image", "url", ["subexpr", "@mut", [["get", "newUrl", ["loc", [null, [85, 10], [85, 16]]]]], [], []]], ["loc", [null, [83, 4], [86, 6]]]], ["attribute", "href", ["concat", [["get", "newUrl", ["loc", [null, [88, 17], [88, 23]]]]]]], ["attribute", "href", ["concat", [["get", "shareableLink", ["loc", [null, [89, 17], [89, 30]]]]]]], ["block", "each", [["get", "moreImages", ["loc", [null, [102, 14], [102, 24]]]]], [], 0, null, ["loc", [null, [102, 6], [108, 15]]]], ["block", "if", [["get", "hasAgreedToUploadTerms", ["loc", [null, [110, 12], [110, 34]]]]], [], 1, null, ["loc", [null, [110, 6], [116, 13]]]], ["inline", "partial", ["footer"], [], ["loc", [null, [119, 2], [119, 22]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 121,
            "column": 0
          }
        },
        "moduleName": "imgix-inspector/templates/view.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "imgix-params");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "imgix-row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3, "class", "button new");
        dom.setAttribute(el3, "tabindex", "-1");
        var el4 = dom.createTextNode("Add New Operation");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element11 = dom.childAt(fragment, [6]);
        var element12 = dom.childAt(element11, [3, 1]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[3] = dom.createMorphAt(element11, 1, 1);
        morphs[4] = dom.createElementMorph(element12);
        morphs[5] = dom.createMorphAt(element11, 5, 5);
        morphs[6] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "childWindow.isChild", ["loc", [null, [1, 10], [1, 29]]]]], [], 0, null, ["loc", [null, [1, 0], [35, 11]]]], ["inline", "imgix-image-container", [], ["dpr", ["subexpr", "@mut", [["get", "dpr", ["loc", [null, [38, 6], [38, 9]]]]], [], []], "src", ["subexpr", "@mut", [["get", "newUrl", ["loc", [null, [39, 6], [39, 12]]]]], [], []], "originalImageIsGif", ["subexpr", "@mut", [["get", "originalImageIsGif", ["loc", [null, [40, 21], [40, 39]]]]], [], []], "shouldDisplayTransparencyGrid", ["subexpr", "@mut", [["get", "shouldDisplayTransparencyGrid", ["loc", [null, [41, 32], [41, 61]]]]], [], []]], ["loc", [null, [37, 0], [42, 2]]]], ["block", "if", [["get", "uploadProgress", ["loc", [null, [44, 6], [44, 20]]]]], [], 1, null, ["loc", [null, [44, 0], [48, 7]]]], ["block", "if", [["get", "shouldDisplayPathDropdown", ["loc", [null, [51, 8], [51, 33]]]]], [], 2, null, ["loc", [null, [51, 2], [64, 9]]]], ["element", "action", ["addNew"], [], ["loc", [null, [66, 12], [66, 31]]]], ["block", "each", [["get", "params", ["loc", [null, [69, 10], [69, 16]]]]], [], 3, null, ["loc", [null, [69, 2], [79, 11]]]], ["block", "unless", [["get", "childWindow.isChild", ["loc", [null, [81, 10], [81, 29]]]]], [], 4, null, ["loc", [null, [81, 0], [120, 11]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});