define('imgix-inspector/controllers/application', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    childWindow: _ember['default'].inject.service(),

    actions: {
      closeWindow: function closeWindow() {
        window.close();
      }
    }
  });
});