define("imgix-inspector/components/smart-input", ["exports", "ember"], function (exports, _ember) {
  var computed = _ember["default"].computed;
  var observer = _ember["default"].observer;
  var on = _ember["default"].on;
  exports["default"] = _ember["default"].TextField.extend({
    ga: _ember["default"].inject.service(),

    shiftModifier: 10.0,
    normalStep: 1.0,
    floatStep: 0.01,
    validValues: [],
    deprecatedValues: [],
    classNameBindings: ["invalid", "deprecated"],
    attributeBindings: ['autocapitalize', 'autocomplete', 'autocorrect', 'spellcheck'],
    value: null,

    autocapitalize: "off",
    autocomplete: "off",
    autocorrect: "off",
    spellcheck: false,

    /**
     * `selectThreshold` defines a threshold at which to not select the contents of the
     * input. If the value of the input is less than this number, its contents will be selected
     * upon click.
     * @property
     */
    selectThreshold: 8,

    keyDown: function keyDown(event) {
      var toggledValue = false;

      if (event.keyCode === 38) {
        // up arrow
        this._incrementValue(event);
        toggledValue = true;
      } else if (event.keyCode === 40) {
        // down arrow
        this._decrementValue(event);
        toggledValue = true;
      }

      return !toggledValue;
    },

    keyUp: function keyUp(event) {
      if (event.keyCode === 13) {
        // enter
        this.sendAction('onEnter');
      }
    },

    valid: true,
    invalid: computed.not('valid'),

    _setValidity: function _setValidity() {
      this.set('valid', this.get('_valueIsValid'));
    },

    _valueIsValid: computed('validValues.length', 'validValues.[]', 'value', function () {
      return !this.get('value') || this.get('value.length') === 0 || !this.get('validValues.length') || this.get('validValues.length') === 0 || this.get('validValues').contains(this.get('value'));
    }),

    _scheduleValidityCheck: on('init', observer('_valueIsValid', function () {
      if (this.get('_valueIsValid')) {
        this.set('valid', true);
        return;
      }

      _ember["default"].run.debounce(this, this._setValidity, 500);
    })),

    deprecated: computed('deprecatedValues.length', 'value', function () {
      if (!this.get('deprecatedValues.length') || this.get('deprecatedValues.length') === 0) {
        return false;
      }

      var value = this.get('value');
      return this.get('deprecatedValues').contains(value);
    }),

    click: function click() {
      if (this.get('value') && this.get('value').length < this.get('selectThreshold')) {
        this.$().select();
      }

      this.get('ga').trackEvent('param', 'value_selected');
    },

    _incrementValue: function _incrementValue(event) {
      this._changeValue(event, "up");
    },

    _decrementValue: function _decrementValue(event) {
      this._changeValue(event, "down");
    },

    _changeValue: function _changeValue(event) {
      var direction = arguments.length <= 1 || arguments[1] === undefined ? "up" : arguments[1];

      var value = Number(this.get('value'));

      if (this.get('value') === null || isNaN(value)) {
        return;
      }

      var step = 0;
      var modifier = event.shiftKey ? this.get('shiftModifier') : 1;

      if (Math.abs(value) >= 1 || value === 0) {
        step = this.get('normalStep');
      } else if (Math.abs(value) > 0 && Math.abs(value) < 1) {
        step = this.get('floatStep');
      }

      var sign = direction === "up" ? 1 : -1;
      var newValue = value + sign * step * modifier;
      newValue = newValue % 1 === 0 ? newValue : newValue.toFixed(2);
      this.set('value', newValue);

      this.get('ga').trackEvent('param', 'value_changed', 'with_keyboard');
    },

    _bubbleUpValueChange: observer('value', function () {
      if (!this.attrs || !this.attrs.onChange) {
        return;
      }

      this.attrs.onChange(this.get('value'));
    })
  });
});