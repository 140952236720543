define('imgix-inspector/services/child-window', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Service.extend({
    _window: computed(function () {
      return window;
    }),

    isChild: computed(function () {
      return this.get('_window').isChildWindow;
    }),

    childWindowType: computed(function () {
      return this.get('_window').childWindowType;
    }),

    noChildWindowType: computed('childTypeIsBlend', 'childTypeIsMask', 'childTypeIsMark', function () {
      return !this.get('childTypeIsBlend') && !this.get('childTypeIsMask') && !this.get('childTypeIsMark');
    }),

    childTypeIsBlend: computed.equal('childWindowType', 'blend'),
    childTypeIsMask: computed.equal('childWindowType', 'mask'),
    childTypeIsMark: computed.equal('childWindowType', 'mark')
  });
});