define('imgix-inspector/components/example-image', ['exports', 'ember', 'uri.js'], function (exports, _ember, _uriJs) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    url: null,
    linkUrl: computed.oneWay('url'),

    path: computed('url', function () {
      var uri = (0, _uriJs['default'])(this.get('url'));
      return uri.pathname() + uri.search();
    })
  });
});