define('imgix-inspector/mixins/uploads-files', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Mixin.create({
    uploading: false,
    uploadProgress: null,

    _config: computed(function () {
      return this.container.lookupFactory('config:environment');
    }),

    validateFile: function validateFile(file) {
      if (!file) {
        return false;
      }

      if (file.type.indexOf('image/') !== 0 && file.name.indexOf('.pdf') === -1 && file.name.indexOf('.ai') === -1) {
        alert('Please select an image file');
        return;
      }

      if (file.size > 50e6) {
        alert('Please select an image under 50 MB');
        return false;
      }

      return true;
    },

    uploadFile: function uploadFile(file) {
      var _this = this;

      if (!file) {
        alert('Please select a file to upload.');
        return;
      }

      this.set('uploading', true);

      var sandboxReq = _ember['default'].$.ajax({
        url: this.get('_config').APP.UPLOADS.SANDBOX_UPLOAD_POLICIES_URL,
        method: 'post'
      }).fail(function () {
        _this.set('uploading', false);
        alert('Something went wrong! We\'ve been notified, and are looking into it.');
      });

      sandboxReq.done(function (s3Data) {
        var fd = new FormData();

        for (var k in s3Data) {
          fd.append(k, s3Data[k]);
        }

        fd.append('Content-Type', file.type);
        fd.append('file', file, file.name);

        var s3Req = _ember['default'].$.ajax({
          url: _this.get('_config').APP.UPLOADS.S3_URL,
          method: 'post',
          data: fd,
          processData: false,
          contentType: false,
          dataType: 'xml',
          xhr: function xhr() {
            var xhr = _ember['default'].$.ajaxSettings.xhr();

            xhr.upload.addEventListener('progress', function (e) {
              var uploadPercent = ~ ~(e.loaded / e.total * 100);
              _this.set('uploadProgress', uploadPercent);

              if (_this.attrs.uploadProgressed) {
                _this.attrs.uploadProgressed(uploadPercent);
              }
            }, false);

            return xhr;
          }
        }).fail(function (jqXHR, textStatus, errorThrown) {
          console.error(jqXHR, textStatus, errorThrown);
          _this.set('uploading', false);
          alert('There was an error: ' + errorThrown);
        });

        s3Req.done(function (res) {
          var imageKey = _this.$(res).find('Key').text();

          var imgixSource = _this.get('_config').APP.UPLOADS.IMGIX_SOURCE;
          var imageUrl = imgixSource + '/' + imageKey;

          _this.set('uploading', false);

          // Send upload event to Segment
          window.analytics.track("Sandbox - Uploaded File", { url: imageUrl });

          if (_this.attrs.onUploadSuccess) {
            _this.attrs.onUploadSuccess(imageUrl, true, true);
          }
        });
      });
    }
  });
});