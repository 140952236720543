define('imgix-inspector/controllers/create', ['exports', 'ember', 'uri.js'], function (exports, _ember, _uriJs) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    childWindow: _ember['default'].inject.service(),

    urlToCreate: null,
    pathService: _ember['default'].inject.service(),
    source: computed.readOnly('pathService.source'),
    paths: computed.readOnly('pathService.paths'),
    hasAgreedToUploadTerms: false,

    defaultWidth: 600,

    _randomUrl: function _randomUrl() {
      var paths = this.get('paths');
      var path = paths[Math.ceil(Math.random() * paths.length)];

      return this.get('_client').buildURL(path, { w: 400, h: 300, fit: 'crop' });
    },

    _client: computed(function () {
      return new ImgixClient({
        host: this.get('source'),
        includeLiraryParam: false
      });
    }),

    actions: {
      go: function go(targetUrl) {
        var hasAgreedToUploadTerms = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];
        var viaUpload = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

        var url = (0, _uriJs['default'])(targetUrl || this.get('urlToCreate'));

        if (!url.hasQuery('w') && !url.hasQuery('h')) {
          url.addQuery('w', this.get('defaultWidth'));
        }

        var params = { url: url.toString() };

        if (hasAgreedToUploadTerms) {
          params.hasAgreedToUploadTerms = 1;
        }

        // Send add event to Segment. We only want to track non-uploads here.
        // Uploads are tracked in the uploads-files mixin.
        if (!viaUpload) {
          window.analytics.track("Sandbox - Added URL", { url: url.toString() });
        }

        this.transitionToRoute('view', { queryParams: params });
        this.set('urlToCreate', null);
      },

      feelingLucky: function feelingLucky() {
        this.transitionToRoute('view', { queryParams: { url: this._randomUrl() } });
      }
    }
  });
});
/* global ImgixClient */