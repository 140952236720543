define('imgix-inspector/components/imgix-input-row', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Component.extend({
    ga: _ember['default'].inject.service(),
    debounceWait: 500,

    classNameBindings: [':imgix-row', ':imgix-input-row', 'param.addedByUser:new-input-row'],

    paramValue: computed.oneWay('param.value'),

    _setParamValue: function _setParamValue(value) {
      this.get('param').set('value', value);
    },

    actions: {
      removeItem: function removeItem() {
        this.attrs.removeItem(this.get('param'));
      },

      buildUrl: function buildUrl() {
        this.attrs.buildUrl(this.get('param'));
      },

      onKeyChange: function onKeyChange() {
        var param = this.get('param');
        param.set('value', null);

        this.get('ga').trackEvent('param', 'key_changed', param.get('key'));
      },

      // We practice DDAU in this specific instance to catch the values changing and apply them
      // at a safe interval.
      onValueChange: function onValueChange(newValue) {
        run.debounce(this, this._setParamValue, newValue, this.get('debounceWait'));
      }
    }
  });
});