define("imgix-inspector/components/readonly-textarea", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].TextArea.extend({
    attributeBindings: ["readonly"],
    classNames: ["copy-link"],

    readonly: true,

    click: function click() {
      var element = this.get('element');
      element.focus();
      element.select();
    }
  });
});