define('imgix-inspector/components/image-with-loading-wrapper', ['exports', 'ember', 'jquery', 'uri.js', 'imgix-inspector/models/promise-object', 'ember-network/fetch'], function (exports, _ember, _jquery, _uriJs, _imgixInspectorModelsPromiseObject, _emberNetworkFetch) {
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  exports['default'] = _ember['default'].Component.extend({
    sizes: _ember['default'].inject.service(),

    src: null,
    dpr: 1,

    resizeCounter: 0,

    _cssDataPromise: computed('src', function () {
      if (!this.get('src')) {
        return null;
      }

      var src = (0, _uriJs['default'])(this.get('src'));
      if (!src.hasQuery('palette', 'css')) {
        return null;
      }

      var promise = (0, _emberNetworkFetch['default'])(src.toString()).then(function (response) {
        return response.text();
      });

      return _imgixInspectorModelsPromiseObject['default'].create({
        promise: promise
      });
    }),

    cssData: computed.reads('_cssDataPromise.content'),

    _cssDataAsJsonPromise: computed('_cssDataPromise', function () {
      if (!this.get('_cssDataPromise')) {
        return null;
      }

      var src = (0, _uriJs['default'])(this.get('src'));
      src.removeSearch('palette');
      src.addQuery('palette', 'json');

      var promise = (0, _emberNetworkFetch['default'])(src.toString()).then(function (response) {
        return response.json();
      }).then(function (result) {
        return result.colors;
      });

      return _imgixInspectorModelsPromiseObject['default'].create({
        promise: promise
      });
    }),

    cssColors: computed.reads('_cssDataAsJsonPromise.content'),

    _stringDataPromise: computed('src', function () {
      if (!this.get('src')) {
        return null;
      }

      var src = (0, _uriJs['default'])(this.get('src'));
      if (!src.hasQuery('fm', 'blurhash')) {
        return null;
      }

      var promise = (0, _emberNetworkFetch['default'])(src.toString()).then(function (response) {
        return response.text();
      });

      return _imgixInspectorModelsPromiseObject['default'].create({
        promise: promise
      });
    }),

    stringData: computed.reads('_stringDataPromise.content'),

    _jsonDataPromise: computed('src', function () {
      if (!this.get('src')) {
        return null;
      }

      var src = (0, _uriJs['default'])(this.get('src'));

      if (!src.hasQuery('palette', 'json') && !src.hasQuery('fm', 'json')) {
        return null;
      }

      var promise = (0, _emberNetworkFetch['default'])(src.toString()).then(function (response) {
        return response.json();
      });

      return _imgixInspectorModelsPromiseObject['default'].create({
        promise: promise
      });
    }),

    jsonData: computed.reads('_jsonDataPromise.content'),

    prettyPrintedCssData: computed('cssData', function () {
      return _jquery['default'].trim(this.get('cssData'));
    }),

    prettyPrintedStringData: computed('stringData', function () {
      return this.get('stringData');
    }),

    prettyPrintedJsonData: computed('jsonData', function () {
      return JSON.stringify(this.get('jsonData'), null, 2);
    }),

    _listenToWindowResizeEvents: on('init', function () {
      var _this = this;

      var resizeHandler = function resizeHandler() {
        _ember['default'].run.throttle(_this, _this._incrementResizeCounter, 100);
      };

      (0, _jquery['default'])(window).on('resize', resizeHandler);
      this.set('_resizeHandler', resizeHandler);
    }),

    _incrementResizeCounter: function _incrementResizeCounter() {
      this.incrementProperty('resizeCounter');
    },

    willDestroyElement: function willDestroyElement() {
      var resizeHandler = this.get('_resizeHandler');
      (0, _jquery['default'])(window).off('resize', resizeHandler);
      this._super();
    },

    aspectRatio: computed('imageWidth', 'imageHeight', function () {
      if (!this.get('imageHeight')) {
        return 1; // This is just a safe default to work with
      }

      return this.get('imageWidth') / this.get('imageHeight');
    }),

    $wrapperEl: computed('hasInsertedElement', function () {
      if (!this.get('hasInsertedElement')) {
        return null;
      }

      return this.$('.imgix-wrap');
    }),

    imageStyle: computed('cssData', 'jsonData', 'stringData', '$wrapperEl', 'dpr', 'imageWidth', 'aspectRatio', 'resizeCounter', function () {
      if (this.get('jsonData') || this.get('cssData') || this.get('stringData')) {
        return "display: none;";
      }

      if (!this.get('$wrapperEl')) {
        return null;
      }

      // The maximum height of the wrapper, given the viewport dimensions
      var maxWrapperHeight = parseInt(this.get('$wrapperEl').css('maxHeight'), 10);

      // This catch allows us to avoid setting the imageStyle width to 0, thereby
      // causing the image to flash, which is gross.
      if (!this.get('imageWidth')) {
        return new _ember['default'].Handlebars.SafeString('height: ' + maxWrapperHeight + 'px');
      }

      // convert maxWrapperHeight to a max image width
      var maxImageWidthBasedOnMaxWrapperHeight = maxWrapperHeight * this.get('aspectRatio');
      var naturalImageWidth = this.get('imageWidth') / this.get('dpr');
      var currentContainerWidth = this.get('$wrapperEl').width();

      var width = Math.min(naturalImageWidth, maxImageWidthBasedOnMaxWrapperHeight, currentContainerWidth);

      return new _ember['default'].Handlebars.SafeString('width: ' + width + 'px');
    }),

    isLoaded: computed.or('cssData', 'jsonData', 'stringData', '_imageHasLoaded'),

    imageWidth: computed.alias('sizePromise.content.width'),
    imageHeight: computed.alias('sizePromise.content.height'),

    sizePromise: computed('src', function () {
      return _imgixInspectorModelsPromiseObject['default'].create({
        promise: this.get('sizes').sizePromiseFor(this.get('src'))
      });
    }),

    _srcChanged: observer('src', function () {
      this.setProperties({
        _imageHasLoaded: false
      });
    }),

    /**
     * This method is used to let our parents know when we have a loaded
     * and sized image element that can be measured. We need to make sure
     * 'imageStyle' is set so that we do not give our parent a 0px wide image.
     */
    _imageLoaded: observer('imageStyle', '_imageHasLoaded', function () {
      if (this.get('imageStyle') && this.get('_imageHasLoaded')) {
        var img = this.$('img');
        this.sendAction('imageLoaded', img);
      }
    }),

    _didLoad: function _didLoad() {
      this.set('_imageHasLoaded', true);
    },

    _didError: function _didError() {
      this.sendAction('imageErrored');
      this.set('_imageHasLoaded', false);
    },

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this.$('img').on('load', _ember['default'].run.bind(this, this._didLoad));
      this.$('img').on('error', _ember['default'].run.bind(this, this._didError));

      _ember['default'].run.schedule('afterRender', function () {
        _this2.set('hasInsertedElement', true);
      });
    }
  });
});