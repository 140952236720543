define('imgix-inspector/components/smart-input-with-parameter-typing', ['exports', 'ember', 'imgix-inspector/components/smart-input'], function (exports, _ember, _imgixInspectorComponentsSmartInput) {
  var computed = _ember['default'].computed;

  var PLACEHOLDERS = {
    boolean: "true or false",
    number: "a number (e.g. 42)",
    unit_scalar: "a percent (e.g. 0.42)",
    hex_color: "a hex color (e.g. fff)",
    color_keyword: "a color keyword (e.g. \"red\")",
    font: "a supported font name",
    ratio: "a ratio (e.g. 2:1)",
    integer: "an integer (e.g. 3)",
    url: "a URL (e.g. https://google.com/cats.gif)",
    timestamp: "a Unix timestamp (e.g. " + (Date.now() / 1000 + 1 * 60 * 60) + ")",
    border: "a integer and a hex color (e.g. 3,fff)",
    radius: "an integer or 4 integers separated by commas (e.g. 4,5,20,20)",
    rect: "4 integers separated by commas (e.g. 4,5,20,20)",
    duotone: "2 hex colors separated by commas (e.g. fff,000)"
  };

  exports['default'] = _imgixInspectorComponentsSmartInput['default'].extend({
    selectedParameter: null,
    availableParameters: null,
    attributeBindings: ['placeholder'],

    parameterInfo: computed('selectedParameter', 'availableParameters', function () {
      var params = this.get('availableParameters');

      if (!params) {
        return null;
      }

      return params[this.get('selectedParameter')];
    }),

    placeholder: computed('parameterInfo', 'selectedParameter', function () {
      var param = this.get('parameterInfo'),
          paramName = this.get('selectedParameter');

      // One off placeholders
      switch (paramName) {
        case 'border':
          return PLACEHOLDERS.border;

        case 'border-radius':
        case 'border-radius-inner':
        case 'corner-radius':
          return PLACEHOLDERS.radius;

        case 'rect':
          return PLACEHOLDERS.rect;

        case 'duotone':
          return PLACEHOLDERS.duotone;
      }

      if (!param) {
        return "";
      }

      var expects = param.expects;

      if (expects[0]) {
        if (expects[0].min !== undefined && expects[0].max !== undefined) {
          return 'A value between ' + expects[0].min + ' and ' + expects[0].max + ', inclusive';
        } else if (expects[0].possible_values) {
          return this.humanizeValues(expects[0].possible_values);
        }
      }

      if (typeof expects === "string") {
        expects = new Array(expects);
      }

      expects = _ember['default'].A(expects);

      var placeholders = expects.map(function (expectation) {
        return PLACEHOLDERS[expectation.type];
      });

      return this.capitalize(placeholders.join(' or '));
    }),

    humanizeValues: function humanizeValues(values) {
      if (!values) {
        return "";
      } else if (values.length === 1) {
        return values[0];
      } else if (values.length === 2) {
        return values.join(' or ');
      } else {
        var lastValue = values[values.length - 1];
        var firstValues = values.slice(0, -1);
        return firstValues.join(', ') + ", or " + lastValue;
      }
    },

    capitalize: function capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  });
});