define('imgix-inspector/services/sizes', ['exports', 'ember', 'base64', 'ember-network/fetch'], function (exports, _ember, _base64, _emberNetworkFetch) {
  exports['default'] = _ember['default'].Service.extend({
    sizePromiseFor: function sizePromiseFor(url) {
      var sizeUrl = 'https://imgix-sandbox-server.herokuapp.com/size?url64=' + _base64['default'].encodeURI(url);
      return (0, _emberNetworkFetch['default'])(sizeUrl).then(function (response) {
        return response.json();
      }).then(function (data) {
        return data;
      });
    }
  });
});