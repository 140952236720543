define("imgix-inspector/mixins/pardot-tracker", ["exports", "ember"], function (exports, _ember) {

  /* global piTracker */

  exports["default"] = _ember["default"].Mixin.create({
    activate: function activate() {
      this._super();
      if (typeof piTracker !== "undefined") {
        _ember["default"].run.later(function () {
          piTracker(window.location.origin + window.location.pathname);
        });
      }
    }
  });
});