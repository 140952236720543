define('imgix-inspector/controllers/view', ['exports', 'ember', 'imgix-inspector/models/promise-object', 'jquery', 'uri.js', 'bowser', 'ember-network/fetch', 'imgix-inspector/models/param'], function (exports, _ember, _imgixInspectorModelsPromiseObject, _jquery, _uriJs, _bowser, _emberNetworkFetch, _imgixInspectorModelsParam) {
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var on = _ember['default'].on;
  exports['default'] = _ember['default'].Controller.extend({
    childWindow: _ember['default'].inject.service(),
    pathService: _ember['default'].inject.service(),
    detect: _ember['default'].inject.service(),
    ga: _ember['default'].inject.service(),
    sizes: _ember['default'].inject.service(),

    queryParams: ['url', 'secureUrlToken', 'hasAgreedToUploadTerms', 'experimental'],
    url: null,
    experimental: false,
    secureUrlToken: null,
    hasAgreedToUploadTerms: null,
    modifiedContentType: null,

    paths: computed.readOnly('pathService.paths'),

    protocol: computed.alias('model.protocol'),
    source: computed.alias('model.source'),
    path: computed.alias('model.path'),
    params: computed.alias('model.params'),

    imageDisplayedAtNaturalDimensions: true,

    useSelect2: computed('detect.isDesktop', '_config', function () {
      return this.get('detect.isDesktop') && this.get('_config').APP.useSelect2 !== false;
    }),

    originalImageIsGif: computed('path', function () {
      return this.get('path') && this.get('path').indexOf('.gif') > -1;
    }),

    shouldDisplayPathDropdown: computed('source', 'path', function () {
      return this.get('source') === "assets.imgix.net" && this.get('paths').contains(this.get('path'));
    }),

    needsTransparencyGrid: computed('modifiedContentType', 'originalContentType', function () {
      return this.get('modifiedContentType') === "image/png" || this.get('modifiedContentType') === "image/gif" || this.get('modifiedContentType') === "image/webp" && (this.get('originalContentType') === "image/gif" || this.get('originalContentType') === "image/png");
    }),

    /**
     * To prevent flashing while loading in an image, we use an observer instead of a computed property
     * to set/unset whether or not to display the transparent background.
     */
    _setShouldDisplayTransparencyGrid: observer('needsTransparencyGrid', 'modifiedContentType', function () {
      if (!this.get('modifiedContentType')) {
        return;
      }

      this.set('shouldDisplayTransparencyGrid', this.get('needsTransparencyGrid'));
    }),

    shouldDisplayTransparencyGrid: false,

    originalContentType: computed.oneWay('_originContentTypePromise.content'),

    /**
     * `newUrl` represents the URL that gets built by Sandbox. This is the money-maker.
     * We take all of the parameters defined by the user in each input row and roll them
     * up into a single URL that we use to build the image.
     *
     * @return String
     */
    newUrl: computed('_client', 'path', 'params.@each.key', 'params.@each.value', 'originalImageIsGif', function () {
      if (!this.get('_client') || !this.get('path')) {
        return null;
      }

      var params = this.get('params');
      var newParams = {};

      params.copy().reverse().forEach(function (param) {
        var key = _ember['default'].get(param, 'key');
        var val = _ember['default'].get(param, 'value');

        if (key && (val || val === 0)) {
          var trimmedVal = _jquery['default'].trim(val);

          if (['txt', 'mark', 'blend', 'txtfont'].indexOf(key) > -1) {
            newParams[key + '64'] = trimmedVal;
          } else {
            newParams[key] = trimmedVal;
          }
        }
      });

      if (this.get('originalImageIsGif')) {
        newParams["frame"] = "1";
      }

      return this.get('_client').buildURL(this.get('path'), newParams);
    }),

    shareableLink: computed('newUrl', function () {
      return this._viewUrlFor(this.get('newUrl'));
    }),

    /**
     * `txtValue` tracks any txt value input specified by the user
     * @property txtValue
     * @type String
     * @default null
     */
    txtValue: computed('params.@each.key', 'params.@each.value', function () {
      if (!this.get('params')) {
        return null;
      }

      return this.get('params').filter(function (param) {
        return param.get('key') === "txt";
      }).mapBy('value').get('firstObject');
    }),

    _txtValueCounter: 0,

    /**
     * `_incrementTxtValueCounter` increments the `_txtValueCounter` only upon change.
     * This prevents us from constantly churning on `originalUrl`s.
     */
    _incrementTxtValueCounter: observer('txtValue', function () {
      var txtValue = this.get('txtValue');
      var oldTxtValue = this.get('_oldTxtValue');

      if (txtValue === oldTxtValue) {
        return;
      }

      this.incrementProperty('_txtValueCounter');
      this.set('_oldTxtValue', txtValue);
    }),

    /**
     * The `originalUrl` is the URL that represents our unmodified
     */
    originalUrl: computed('protocol', 'source', 'path', '_txtValueCounter', function () {
      if (!this.get('source') || !this.get('path')) {
        return null;
      }

      var uri = new _uriJs['default']({
        protocol: this.get('protocol'),
        hostname: this.get('source'),
        path: this.get('path')
      });

      if (this.get('txtValue')) {
        uri.addQuery('txt', this.get('txtValue'));
      }

      return uri.toString();
    }),

    dpr: computed('params.@each.value', 'params.@each.key', function () {
      return this.get('params').filter(function (param) {
        return param.get('key') === "dpr";
      }).get('firstObject.value') || 1;
    }),

    /**
     * `_client` instatiates and returns an ImgixClient used to build up our imgix URLs.
     *
     * @private
     * @return ImgixClient
     */
    _client: computed('protocol', 'source', 'secureUrlToken', function () {
      if (!this.get('source')) {
        return null;
      }

      var useHttps = this.get('protocol') !== "http";

      if (this.get('secureUrlToken')) {
        return new ImgixClient({
          host: this.get('source'),
          secureURLToken: this.get('secureUrlToken'),
          useHTTPS: useHttps,
          includeLibraryParam: false
        });
      } else {
        return new ImgixClient({
          host: this.get('source'),
          useHTTPS: useHttps,
          includeLibraryParam: false
        });
      }
    }),

    /**
     * `_sendNewUrlChangesToParent` binds an observer to our `newUrl` property to relay
     * any changes made to the property to our parent window. This lets users apply blends, masks,
     * and marks easily.
      * @private
     */
    _sendNewUrlChangesToParent: on('init', observer('newUrl', function () {
      window.postMessage(this.get('newUrl'), window.location.origin);
    })),

    /**
     * `_parametersPromise` returns a promise that signifies the paylod from the zebrabishlabs/image-api-spec
     * parameters payload. This payload tells us lots of information about the possible imgix URL API parameters,
     * their defaults, their possible values, and which ones are deprecated.
     *
     * @return {PromiseObject}
     */
    _parametersPromise: computed(function () {
      var promise = (0, _emberNetworkFetch['default'])('/assets/parameters.json').then(function (response) {
        return response.json();
      });

      return _imgixInspectorModelsPromiseObject['default'].create({
        promise: promise
      });
    }),

    _config: computed(function () {
      return this.container.lookupFactory('config:environment');
    }),

    availableParameters: computed('_parametersPromise.content', function () {
      var paramContent = this.get('_parametersPromise').content;

      if (paramContent) {
        var availableParams = paramContent.parameters;

        if (this.get('experimental')) {
          availableParams = _ember['default'].merge(availableParams, paramContent.experimentalParameters);
        }

        return availableParams;
      } else {
        return {};
      }
    }),
    deprecatedParameters: computed.alias('_parametersPromise.content.deprecatedParameters'),
    aliasedParameters: computed.alias('_parametersPromise.content.aliasedParameters'),

    deprecatedParameterKeys: computed('deprecatedParameters', function () {
      return Object.keys(this.get('deprecatedParameters') || {});
    }),

    moreImages: computed('hasAgreedToUploadTerms', 'params.@each.value', 'params.@each.key', '_client', function () {
      var examples = ["https://assets.imgix.net/unsplash/bridge.jpg", "https://assets.imgix.net/unsplash/pineneedles.jpg", "https://assets.imgix.net/unsplash/turntable.jpg"];

      if (!this.get('hasAgreedToUploadTerms')) {
        examples.push("https://assets.imgix.net/unsplash/transport.jpg");
      }

      var params = this._trimmedParams();

      if (params.fm && (params.fm === "json" || params.fm === "blurhash")) {
        delete params.fm;
      }

      if (params.palette) {
        delete params.palette;
      }

      return examples.map(function (url) {
        var imageUrl = (0, _uriJs['default'])(url);
        imageUrl.addQuery(params);

        return _ember['default'].Object.create({
          url: imageUrl,
          viewUrl: url
        });
      });
    }),

    _trimmedParams: function _trimmedParams() {
      var params = this.get('params');
      var newParams = {};

      params.copy().reverse().forEach(function (param) {
        var key = _ember['default'].get(param, 'key');
        var val = _ember['default'].get(param, 'value');

        if (key && (val || val === 0)) {
          var trimmedVal = _jquery['default'].trim(val);
          newParams[key] = trimmedVal;
        }
      });

      return newParams;
    },

    /**
     * `_viewUrlFor` returns a fully-qualified URL that represents the current state of the
     * Sandbox, i.e. the state on /view.
     * @return String
     */
    _viewUrlFor: function _viewUrlFor(url) {
      if (!url || _jquery['default'].isNumeric(url) || url.indexOf("http") < 0) {
        return "/create";
      }

      var u = (0, _uriJs['default'])(window.location.origin);
      u.pathname("/view");
      u.addQuery("url", url);
      return u.toString();
    },

    _window: computed(function () {
      return window;
    }),

    _reflectChangesToNewUrlInUrlParameters: observer('newUrl', function () {
      this.set('url', this.get('newUrl'));
    }),

    _browserImageAcceptHeader: computed(function () {
      if (_bowser['default'].chrome) {
        return "image/webp,*/*;q=0.8"; // This is Chrome 43's <img> Accept header
      } else if (_bowser['default'].firefox) {
          return null; // Firefox will request image/webp, imgix will not serve it out however
        } else if (_bowser['default'].msie && _bowser['default'].version >= 9) {
            return "image/jxr,*/*;q=0.8";
          }
    }),

    /**
     * Returns the correct value to center a child window created via `window.open`.
     * @return String
     */
    _childWindowPosition: function _childWindowPosition() {
      var width = 900;
      var height = 800;
      var parentX = this.get('_window').screenX;
      var parentY = this.get('_window').screenY;
      var parentWidth = this.get('_window').innerWidth;
      var parentHeight = this.get('_window').innerHeight;

      var x = parentX + parentWidth / 2 - width / 2;
      var y = parentY + parentHeight / 2 - height / 2;

      return 'width=' + width + ',height=' + height + ',left=' + x + ',top=' + y;
    },

    _scrollWindowToTop: function _scrollWindowToTop() {
      _ember['default'].run.next(function () {
        _ember['default'].$("html, body").animate({
          scrollTop: "0px",
          duration: 100
        });
      });
    },

    actions: {
      /**
       * `addNew` will create a new parameter for our URL that we’re building up,
       * and focus the new input.
       */
      addNew: function addNew() {
        var _this = this;

        var params = this.get('params');
        params.unshiftObject(_imgixInspectorModelsParam['default'].create({ addedByUser: true }));

        _ember['default'].run.later(function () {
          if (!_this.get('detect.isMobile') && !_this.get('detect.isTablet')) {
            // Don't auto-focus the recently added input on mobile
            (0, _jquery['default'])('.imgix-input-row:first .select2-container').select2('open');
          }
        }, 500);

        this.get('ga').trackEvent('param', 'added');
      },

      /**
       * `buildUrl` opens a new window to edit a `blend`, `mark`, or `mask` parameter,
       * and attaches event listeners to receives events from the child window.
       */
      buildUrl: function buildUrl(param) {
        var value = param.get('value');
        var url = this._viewUrlFor(value);

        var child = this.get('_window').open(url, +new Date(), this._childWindowPosition());
        child.isChildWindow = true;
        child.childWindowType = param.get('key');

        child.addEventListener("message", function (message) {
          if (typeof message.data === "string") {
            param.set("value", message.data);
          }
        }, false);

        this.get('ga').trackEvent('childUrl', 'built');
      },

      removeItem: function removeItem(item) {
        this.get('params').removeObject(item);

        this.get('ga').trackEvent('param', 'removed');
      },

      replaceImage: function replaceImage(newImageUrl) {
        var scrollToTop = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

        this.set('uploading', false);
        this.set('uploadProgress', null);

        var uri = new _uriJs['default'](newImageUrl);
        var source = uri.hostname();
        var path = uri.path();

        this.setProperties({
          source: source,
          path: path
        });

        if (scrollToTop) {
          this._scrollWindowToTop();
        }
      },

      contentTypeChanged: function contentTypeChanged(contentType) {
        this.set('modifiedContentType', contentType);
      },

      selectChanged: function selectChanged(event) {
        this.set('path', event.target.value);

        this.get('ga').trackEvent('image', 'changed');
      },

      onUploadStart: function onUploadStart() {
        this._scrollWindowToTop();
        this.set('uploading', true);
      },

      onUploadProgress: function onUploadProgress(uploadProgressPercent) {
        this.set('uploadProgress', uploadProgressPercent);
      }
    }
  });
});
/* global ImgixClient */