define('imgix-inspector/components/smart-input-with-parameter-validation', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':param-wrap'],
    value: null,
    availableParameters: null,
    useSelect2: true,

    availableParameterKeys: computed('availableParameters', function () {
      return Object.keys(this.get('availableParameters') || {});
    }),

    parameterInfo: computed('value', 'availableParameters', function () {
      var params = this.get('availableParameters');

      if (!params) {
        return null;
      }

      return params[this.get('value')];
    }),

    select2Content: computed('value', 'availableParameterKeys', function () {
      var availableParameters = this.get('availableParameterKeys');

      if (!availableParameters) {
        return null;
      }

      return availableParameters.sort(function (a, b) {
        return a.localeCompare(b);
      }).map(function (param) {
        return {
          id: param,
          text: param
        };
      });
    }),

    select2Value: computed('value', 'select2Content', function () {
      var value = this.get('value');
      var select2Content = this.get('select2Content');

      if (!value || !select2Content) {
        return null;
      }

      return select2Content.findBy('id', value);
    }),

    actions: {
      onChange: function onChange(select2Value) {
        this.set('value', select2Value ? select2Value.id : null);
        this.attrs.onChange();
      },

      query: function query(_query, deferred) {
        var sortByLength = !!_query.term && _query.term !== "";
        var content = this.get('select2Content').copy();

        var sortedContent = content.filter(function (s) {
          return sortByLength ? s.id.indexOf(_query.term) > -1 : true;
        }).sort(function (a, b) {
          return a.id.localeCompare(b.id);
        }).sort(function (a, b) {
          if (sortByLength) {
            return a.id.length <= b.id.length ? -1 : 1;
          } else {
            return a.id.localeCompare(b.id);
          }
        });
        deferred.resolve(sortedContent);
      }
    }
  });
});