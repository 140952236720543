define('imgix-inspector/components/info-block', ['exports', 'ember', 'imgix-inspector/models/promise-object', 'uri.js'], function (exports, _ember, _imgixInspectorModelsPromiseObject, _uriJs) {
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['info-block'],
    name: null,
    src: null,
    sizes: _ember['default'].inject.service(),

    _imagePromise: computed('src', function () {
      if (!this.get('src')) {
        return null;
      }

      return _imgixInspectorModelsPromiseObject['default'].create({
        promise: this.get('sizes').sizePromiseFor(this.get('src'))
      });
    }),

    image: computed.oneWay('_imagePromise.content'),

    size: computed.readOnly('_imagePromise.content.size'),
    contentType: computed.readOnly('_imagePromise.content.content_type'),

    contentTypeIsJson: computed.equal('contentType', 'application/json'),
    contentTypeIsCss: computed.equal('contentType', 'text/css'),
    contentTypeIsString: computed.equal('contentType', 'text/plain'),

    onlyHasTxtParameter: computed('src', function () {
      var uri = (0, _uriJs['default'])(this.get('src'));
      return uri.hasQuery('txt') && Object.keys(uri.search(true)).length === 1;
    }),

    isTextEndpointWithoutParameters: computed('src', function () {
      var uri = (0, _uriJs['default'])(this.get('src'));

      return this.get('src') && uri.pathname() === "/~text" && (uri.search() === "" || this.get('onlyHasTxtParameter'));
    }),

    shouldNotDisplayContentType: computed.or('isTextEndpointWithoutParameters', 'contentTypeIsJson', 'contentTypeIsCss', 'contentTypeIsString'),

    dimensions: computed('height', 'width', function () {
      var width = this.get('width');
      var height = this.get('height');

      return height && width ? width + '×' + height : null;
    }),

    _turnContentTypeChangedIntoAction: observer('contentType', function () {
      this.sendAction('contentTypeChanged', this.get('contentType'));
    }),

    /**
    * Both `height` and `width` have to use the prop methods, for
    * a few reasons. Ember tends to break if we start passing around raw elements
    * instead of jQuery objects.
    *
    * The complicated computed properties here with private storage allow us to
    * have some smart logic around getting/setting values.
    */
    height: computed.alias('_imagePromise.content.height'),
    width: computed.alias('_imagePromise.content.width')
  });
});